import { ReactComponent as MenuIcon } from "../../../assets/images/fi-rr-align-right.svg";
import ButtonBase from "@mui/material/ButtonBase";
import "./DrawerMenu.scss";

import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import { useState } from "react";
import { navigateToSection } from "../../../utils/navigateToSection";

type MenuItemProps = {
  path: string;
  name: string;
};
type Props = {
  items: MenuItemProps[];
};

export const DrawerMenu = ({ items }: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <>
      <ButtonBase
        id="basic-button"
        onClick={handleClick}
        sx={{ padding: "0 0.5rem", height: "100%" }}>
        <MenuIcon />
      </ButtonBase>

      <div
        id="basic-menu"
        className={isClicked ? "main-menu-opened" : "main-menu"}>
        {items.map(({ name, path }) => {
          return (
            <MenuItem
              key={name}
              className="main-menu-opened__item"
              onClick={(event) => {
                navigateToSection(path, event);
                setIsClicked(!isClicked);
              }}>
              <Link href={"/"}>{name}</Link>
            </MenuItem>
          );
        })}
      </div>
    </>
  );
};
