import { Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout/Layout";
import { SuspenseWithFallback } from "./shared/SuspenseWithFallback/SuspenseWithFallback";
import { ContactUsPage, FeaturesPage, HomePage, PricingPage, ProductsPage, SupportPage } from "./routes";

import "./App.scss";

function App() {
  return (
    <Routes>
      <Route
        index
        element={
          <SuspenseWithFallback>
            <Layout>
              <HomePage />
            </Layout>
          </SuspenseWithFallback>
        }
      />
      <Route
        path="/contact-us"
        element={
          <SuspenseWithFallback>
            <Layout>
              <ContactUsPage />
            </Layout>
          </SuspenseWithFallback>
        }
      />

      <Route
        path="/features"
        element={
          <SuspenseWithFallback>
            <Layout>
              <FeaturesPage />
            </Layout>
          </SuspenseWithFallback>
        }
      />

      <Route
        path="/pricing"
        element={
          <SuspenseWithFallback>
            <Layout>
              <PricingPage />
            </Layout>
          </SuspenseWithFallback>
        }
      />

      <Route
        path="/products"
        element={
          <SuspenseWithFallback>
            <Layout>
              <ProductsPage />
            </Layout>
          </SuspenseWithFallback>
        }
      />

      <Route
        path="/support"
        element={
          <SuspenseWithFallback>
            <Layout>
              <SupportPage />
            </Layout>
          </SuspenseWithFallback>
        }
      />
    </Routes>
  );
}

export default App;
