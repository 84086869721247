import "./Navbar.scss";

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
// import { NavLink } from "react-router-dom";
import { DrawerMenu } from "../DrawerMenu/DrawerMenu";
import Box from "@mui/material/Box/Box";
import { navigateToSection } from "../../../utils/navigateToSection";
import MenuItem from "@mui/material/MenuItem";

const menuItems = [
  { path: "products", name: "Products" },
  { path: "features", name: "Features" },
  { path: "pricing", name: "Pricing" },
  { path: "support", name: "Support" },
];

export function Navbar() {
  return (
    <Grid
      className="navbar"
      container
      columns={16}>
      <Grid
        item
        xs="auto">
        <Link href="/">
          <Logo className="navbar__logo" />
        </Link>
      </Grid>
      <Grid
        sx={{ display: { xs: "none", lg: "flex" } }}
        item
        xs={7}
        className="navbar__links">
        {menuItems.map(({ path, name }) => {
          return (
            <MenuItem
              onClick={(event) => {
                navigateToSection(path, event);
              }}
              key={name}
              className="navbar__links__link">
              {name}
            </MenuItem>
          );
        })}
      </Grid>
      <Grid
        item
        className="navbar__buttons-container"
        xs="auto">
        <Button
          sx={{ display: { md: "block", sm: "none", xs: "none" } }}
          className="navbar__buttons-container__contact-us-button"
          variant="outlined"
          color="secondary">
          Contact us
        </Button>
        <Button
          href="https://datapro.cortex.ag/"
          className="navbar__buttons-container__login-button"
          variant="contained"
          color="primary">
          Log in
        </Button>
        <Box sx={{ display: { lg: "none" } }}>
          <DrawerMenu items={menuItems} />
        </Box>
      </Grid>
    </Grid>
  );
}
