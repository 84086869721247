import CircularProgress from "@mui/material/CircularProgress";
import { Suspense } from "react";

import "./SuspenseWithFallback.scss";

interface Props {
  children: JSX.Element;
}

export const SuspenseWithFallback = ({ children }: Props): JSX.Element => {
  return <Suspense fallback={<CircularProgress />}>{children}</Suspense>;
};
