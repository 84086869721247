import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: { main: "#F0AD4A", contrastText: "#FFF", dark: "#18181B", light: "#FFFFFF" },
    secondary: { main: "#003965", light: "#667085" },
    error: { main: "#FF7792" },
    warning: { main: "#FFD044" },
    info: { main: "#04B2F2" },
    success: { main: "#67E557" },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: "'DM Sans', sans-serif",
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 428,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
});
