import "./Footer.scss";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as Global } from "../../../assets/images/global.svg";
import { ReactComponent as Twitter } from "../../../assets/images/twitter.svg";
import { ReactComponent as Facebook } from "../../../assets/images/facebook.svg";
import { navigateToSection } from "../../../utils/navigateToSection";
import MenuItem from "@mui/material/MenuItem";

const menuItems = [
  { path: "products", name: "Products" },
  { path: "features", name: "Features" },
  { path: "pricing", name: "Pricing" },
  { path: "support", name: "Support" },
];

export function Footer() {
  return (
    <Grid
      className="footer"
      container
      rowGap={4}
      columns={12}>
      <Grid
        className="footer__logo"
        item
        lg={3}
        md={12}
        xs={12}>
        <Link href="/">
          <Logo />
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        className="footer__links">
        {menuItems.map(({ path, name }) => {
          return (
            <MenuItem
              key={name}
              className="footer__link"
              onClick={(event) => {
                navigateToSection(path, event);
              }}>
              {name}
            </MenuItem>
          );
        })}
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        className="footer__socials">
        <Link>
          <Facebook />
        </Link>
        <Link>
          <Twitter />
        </Link>
        <Link>
          <Global />
        </Link>
      </Grid>
    </Grid>
  );
}
