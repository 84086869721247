import { Footer } from "./Footer/Footer";
import "./Layout.scss";
import { Navbar } from "./Navbar/Navbar";

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className="layout">
      <header className="layout__navbar">
        <Navbar />
      </header>
      <main className="layout__main-content">{children}</main>
      <footer className="layout__footer">
        <Footer />
      </footer>
    </div>
  );
}
